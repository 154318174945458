import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";

import "datatables.net";
import "datatables.net-bs5";
import "datatables.net-responsive";
import "datatables.net-responsive-bs5";

import "react-loading-skeleton/dist/skeleton.css";

import "react-toastify/dist/ReactToastify.css";
const SpinnerContainer = lazy(() => import("./components/SpinnerContainer"));
const UserManagement = lazy(() => import("./pages/admin/UserManagement"));
const CourseManagement = lazy(() => import("./pages/admin/CourseManagement"));
const SubscriptionManagement = lazy(() =>
  import("./pages/admin/SubscriptionManagement")
);
const ReportsAnalytics = lazy(() => import("./pages/admin/ReportsAnalytics"));
const ClientManagement = lazy(() => import("./pages/admin/ClientManagement"));

// Client Pages
const DashboardClient = lazy(() => import("./pages/client/DashboardClient"));
const SubscriptionsClient = lazy(() =>
  import("./pages/client/SubscriptionsClient")
);
const CourseClient = lazy(() => import("./pages/client/CourseClient"));
const CertificatesClient = lazy(() =>
  import("./pages/client/CertificatesClient")
);
const EmployeemanageClient = lazy(() =>
  import("./pages/client/EmployeemanageClient")
);
const ProgressClient = lazy(() => import("./pages/client/ProgressClient"));
const PostRegisterClient = lazy(() =>
  import("./pages/client/PostRegisterClient")
);
const PostRegisterAddEmployeeClient = lazy(() =>
  import("./pages/client/PostRegisterAddEmployeeClient")
);

const ClientDetails = lazy(() => import("./pages/admin/ClientDetails"));
const AIChatClient = lazy(() => import("./pages/client/AIChat"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const VerifyOtp = lazy(() => import("./pages/VerifyOtp"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const LoginVerification = lazy(() => import("./pages/LoginVerification"));

// Lazy loading components
// const ClientSideLogin = lazy(() => import("./pages/ClientSideLogin"));
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const Dashboard = lazy(() => import("./pages/admin/Dashboard"));
const LmsCourses = lazy(() => import("./pages/lmsManager/LmsCourses"));
const AddCourse = lazy(() => import("./pages/lmsManager/AddCourse"));
const UpdateCourse = lazy(() => import("./pages/lmsManager/UpdateCourse"));
const AddModule = lazy(() => import("./pages/lmsManager/AddModule/"));
const EditcontentPdf = lazy(() => import("./pages/lmsManager/EditcontentPdf"));
const Exam = lazy(() => import("./pages/lmsManager/Exam"));
const EditExam = lazy(() => import("./pages/lmsManager/EditExam"));
const DashboardMain = lazy(() => import("./pages/employee/DashboardMain"));
const Course = lazy(() => import("./pages/employee/Course"));
const Certificate = lazy(() => import("./pages/employee/Certificate"));
const ProgressTracking = lazy(() =>
  import("./pages/employee/ProgressTracking")
);
const AIChat = lazy(() => import("./pages/employee/AIChat"));
const RegisterEmployee = lazy(() => import("./pages/RegisterEmployee"));
const EmployeeProfile = lazy(() => import("./pages/employee/Profile"));
const ClientProfile = lazy(() => import("./pages/client/Profile"));
const AdminProfile = lazy(() => import("./pages/admin/Profile"));
const PayWithCard = lazy(() => import("./pages/client/PayWithCard"));
const SubscribeCourses = lazy(() => import("./pages/client/SubscribeCourses"));
const CourseDetail = lazy(() => import("./pages/client/CourseDetail"));

const ClientPaymentHistory = lazy(() =>
  import("./pages/admin/ClientPaymentHistory")
);

function App() {
  useEffect(() => {
    import("bootstrap/dist/js/bootstrap");
  }, []);

  return (
    <Router>
      <Suspense fallback={<SpinnerContainer />}>
        <Routes>
          <Route
            exact
            path="/client/:companyId/login"
            element={<RegisterEmployee />}
          />
          <Route exact path="/" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/verify-otp" element={<VerifyOtp />} />
          <Route
            exact
            path="/login-verification"
            element={<LoginVerification />}
          />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/register" element={<Register />} />
          <Route
            exact
            path="/client/:companyId/register"
            element={<RegisterEmployee />}
          />
          {/* Admin Routes */}
          <Route exact path="/admin/dashboard" element={<Dashboard />} />
          <Route
            exact
            path="/admin/user-management"
            element={<UserManagement />}
          />
          <Route
            exact
            path="/admin/course-management"
            element={<CourseManagement />}
          />
          <Route
            exact
            path="/admin/subscription-management"
            element={<SubscriptionManagement />}
          />
          <Route
            exact
            path="/admin/payment-history/:companyId"
            element={<ClientPaymentHistory />}
          />
          <Route
            exact
            path="/admin/reports-analytics"
            element={<ReportsAnalytics />}
          />
          <Route
            exact
            path="/admin/client-management"
            element={<ClientManagement />}
          />
          <Route
            exact
            path="/admin/client/:companyId"
            element={<ClientDetails />}
          />
          <Route exact path="/admin/profile" element={<AdminProfile />} />
          {/* Client Routes */}
          <Route
            exact
            path="/client/:companyId/dashboard"
            element={<DashboardClient />}
          />
          <Route
            exact
            path="/client/:companyId/ai-tutor-chat"
            element={<AIChatClient />}
          />
          <Route
            exact
            path="/client/:companyId/subscriptions"
            element={<SubscriptionsClient />}
          />
          <Route
            exact
            path="/client/:companyId/courses"
            element={<CourseClient />}
          />
          <Route
            exact
            path="/client/:companyId/certificate"
            element={<CertificatesClient />}
          />
          <Route
            exact
            path="/client/:companyId/subscribeCourses"
            element={<SubscribeCourses />}
          />
          <Route
            exact
            path="/client/:companyId/payWithCard"
            element={<PayWithCard />}
          />
          <Route
            exact
            path="/client/:companyId/course-detail/:courseId"
            element={<CourseDetail />}
          />
          <Route
            exact
            path="/client/:companyId/employee-management"
            element={<EmployeemanageClient />}
          />
          <Route
            exact
            path="/client/:companyId/progress"
            element={<ProgressClient />}
          />
          <Route
            exact
            path="/client/:companyId/post-register/share-link"
            element={<PostRegisterClient />}
          />
          <Route
            exact
            path="/client/:companyId/post-register/add-employee"
            element={<PostRegisterAddEmployeeClient />}
          />
          <Route
            exact
            path="/client/:companyId/profile"
            element={<ClientProfile />}
          />
          {/* LMS Manager */}
          <Route exact path="/lmsmanager/login" element={<Login />} />
          <Route exact path="/lmsmanager/courses" element={<LmsCourses />} />
          <Route exact path="/lmsmanager/dashboard" element={<LmsCourses />} />
          <Route exact path="/lmsmanager/add-courses" element={<AddCourse />} />
          <Route exact path="/lmsmanager/profile" element={<AdminProfile />} />
          <Route
            exact
            path="/lmsmanager/update-courses/:id"
            element={<UpdateCourse />}
          />
          <Route
            exact
            path="/lmsmanager/add-module/:courseId"
            element={<AddModule />}
          />
          <Route
            exact
            path="/lmsmanager/edit-content-pdf"
            element={<EditcontentPdf />}
          />
          <Route
            exact
            path="/lmsmanager/create-exam/:courseId"
            element={<Exam />}
          />
          <Route exact path="/lmsmanager/edit-exam" element={<EditExam />} />
          {/* LMS Manager */}
          <Route
            exact
            path={`/employee/:companyId/dashboard`}
            element={<DashboardMain />}
          />
          <Route
            exact
            path={`/employee/:companyId/course/:courseId`}
            element={<Course />}
          />
          <Route
            exact
            path={`/employee/:companyId/certificate`}
            element={<Certificate />}
          />
          <Route
            exact
            path={`/employee/:companyId/progress-tracking`}
            element={<ProgressTracking />}
          />
          <Route
            exact
            path={`/employee/:companyId/ai-tutor-chat`}
            element={<AIChat />}
          />
          <Route
            exact
            path="/employee/:companyId/profile"
            element={<EmployeeProfile />}
          />
          {/* <Route exact path="*" element={<Error />} /> */}
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
