// src/redux/slices/CourseSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCourses = createAsyncThunk("courses/getCourses", async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/courses`);
  return response.data.data;
});

const courseSlice = createSlice({
  name: "courses",
  initialState: {
    loading: false,
    courses: [],
    error: null,
  },
  reducers: {
    addModuleSuccess: (state, action) => {
      const { courseId, module } = action.payload;
      const courseIndex = state.courses.findIndex(
        (course) => course._id === courseId
      );
      if (courseIndex !== -1) {
        const updatedCourse = {
          ...state.courses[courseIndex],
          modules: [...state.courses[courseIndex].modules, module],
        };
        state.courses = [
          ...state.courses.slice(0, courseIndex),
          updatedCourse,
          ...state.courses.slice(courseIndex + 1),
        ];
      }
    },
    updateModuleSuccess: (state, action) => {
      const { courseId, module } = action.payload;
      const courseIndex = state.courses.findIndex(
        (course) => course._id === courseId
      );
      if (courseIndex !== -1) {
        const updatedModules = state.courses[courseIndex].modules.map((m) =>
          m._id === module._id ? module : m
        );
        const updatedCourse = {
          ...state.courses[courseIndex],
          modules: updatedModules,
        };
        state.courses = [
          ...state.courses.slice(0, courseIndex),
          updatedCourse,
          ...state.courses.slice(courseIndex + 1),
        ];
      }
    },
    deleteModuleSuccess: (state, action) => {
      const { courseId, moduleId } = action.payload;
      const courseIndex = state.courses.findIndex(
        (course) => course._id === courseId
      );
      if (courseIndex !== -1) {
        const updatedModules = state.courses[courseIndex].modules.filter(
          (m) => m._id !== moduleId
        );

        const updatedCourse = {
          ...state.courses[courseIndex],
          modules: updatedModules,
        };
        state.courses = [
          ...state.courses.slice(0, courseIndex),
          updatedCourse,
          ...state.courses.slice(courseIndex + 1),
        ];
      }
    },
    updateCourseSuccess: (state, action) => {
      const { courseId, course } = action.payload;
      const courseIndex = state.courses.findIndex(
        (course) => course._id === courseId
      );
      if (courseIndex !== -1) {
        const updatedCourse = {
          ...course,
        };
        state.courses = [
          ...state.courses.slice(0, courseIndex),
          updatedCourse,
          ...state.courses.slice(courseIndex + 1),
        ];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourses.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCourses.fulfilled, (state, action) => {
        state.loading = false;
        state.courses = action.payload;
      })
      .addCase(getCourses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  addModuleSuccess,
  updateModuleSuccess,
  deleteModuleSuccess,
  updateCourseSuccess,
} = courseSlice.actions;

export default courseSlice.reducer;
