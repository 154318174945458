import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getLoginInfoForCompany = createAsyncThunk(
  "company/getCompanyById",
  async (id) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/company/${id}`
    );
    return response.data.data;
  }
);

export const getLoginInfoForEmployee = createAsyncThunk(
  "employee/getEmployeeById",
  async (id) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/company/employee/${id}`
    );
    return response.data.data;
  }
);

export const getLoginInfoForAdmin = createAsyncThunk(
  "admin/getAdminById",
  async (id) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/admin/${id}`
    );
    return response.data.data;
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {
    setLoginInfo: (state, action) => {
      state.data = action.payload;
    },
    updateSectionCompletion: (state, action) => {
      const { sectionId, employeeId } = action.payload;
      state.data.courses.forEach((course) => {
        course.modules.forEach((module) => {
          module.sections.forEach((section) => {
            if (section._id === sectionId) {
              if (!section.completedBy.includes(employeeId)) {
                section.completedBy.push(employeeId);
              }
            }
          });
        });
      });
    },
    updateQuizCompletion: (state, action) => {
      const { quizId, employeeId, marks } = action.payload;
      state.data.courses.forEach((course) => {
        course.modules.forEach((module) => {
          if (module.quiz && module.quiz._id === quizId) {
            module.quiz.marks.push({
              employee: employeeId,
              marks,
            });
          }
        });
      });
    },
    updateCoursePercentage: (state, action) => {
      const { courseId, percentage } = action.payload;
      state.data.courses.forEach((course) => {
        if (courseId === course._id) course.completion = percentage;
      });
    },
    updateCourseExamAttempts: (state, action) => {
      const { courseId, attemptInfo } = action.payload;
      state.data.courses.forEach((course) => {
        if (course._id === courseId) {
          const existingAttempt = course.exam.attempts.find(
            (attempt) => attempt.employee === attemptInfo.employee
          );

          if (existingAttempt) {
            Object.assign(existingAttempt, attemptInfo);
          } else {
            course.exam.attempts.push(attemptInfo);
          }
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoginInfoForCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoginInfoForCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getLoginInfoForCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = null;
      })
      .addCase(getLoginInfoForEmployee.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoginInfoForEmployee.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getLoginInfoForEmployee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = null;
      })
      .addCase(getLoginInfoForAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoginInfoForAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getLoginInfoForAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.data = null;
      });
  },
});

export const {
  setLoginInfo,
  updateSectionCompletion,
  updateCoursePercentage,
  updateQuizCompletion,
  updateCourseExamAttempts,
} = loginSlice.actions;

export default loginSlice.reducer;
