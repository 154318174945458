// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import courseReducer from "./slices/CourseSlice";
import LoginSlice from "./slices/LoginSlice";
import submittedExamInfoReducer from "./slices/submittedExamInfoSlice";
import companyReducer from "./slices/CompanySlice";
import paymentReducer from './slices/paymentSlice';

const store = configureStore({
  reducer: {
    courses: courseReducer,
    login: LoginSlice,
    submittedExamInfo: submittedExamInfoReducer,
    companies: companyReducer,
    payment: paymentReducer,
  },
});

export default store;
